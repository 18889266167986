<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <img
            width="100%"
            v-if="loading"
            :src="'./images/loader.gif'"
            class="mr-2"
            style="margin-top: -5px;"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{this.modalBodyText}}</h4>
              </div>
              <div style="float: right;">
                <button type="button" ref="kt_login_signin_submit" class="btn btn-danger mr-3" @click="changeStatus()">Yes</button>
                <button type="button" class="btn btn-secondary" @click="hideModal">No</button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
           <VerficationModal :show="showModal" :userObj="userObj" :actionType="actionType" @changeStatus="singlechangeStatus" @deleteRow="deleteRow" @closeModal="closeModal"/>
         </template>
         
          <div v-if="alert" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-success">{{alertText}}</div>
          <button
            class="btn btn-success btn btn-success rounded-pill mb-2"

            @click="addLoan"
          >
            Add
          </button>
          <button
            style="float: right; margin-left: 12px"
            class="btn btn-danger btn btn-danger rounded-pill mb-2"
            data-toggle="modal" data-target="#modalId"
            @click="confirmationBulkAction('delete')"
          >
            Delete
          </button>

         
          <!-- <button
            style="float: right;margin-left: 12px"
            class="btn btn-info btn btn-info rounded-pill mb-2"
            @click="confirmationBulkAction('deactive')"
          >
            Deactive
          </button>

          <button
            style="float: right;margin-left: 12px"
            class="btn btn-primary btn btn-primary rounded-pill mb-2"
            id="add-loan-btn"
            @click="confirmationBulkAction('active')"
          >
            Active
          </button> -->
          <div class="card-header cardHeader-bg" style="padding: 14px;">
          <h4 class="card-title mb-0">Complaints</h4>
        </div>
        </div>
        

        <div class="card-body" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(id)="loans">
                <input
                  type="checkbox"
                  :value="loans.item.id"
                  v-model="checkedNames"
                />
              </template>
              <template v-slot:cell(status)="loans">
                <button
                  v-bind:class="changeClass(loans)"
                  @click="singlActiveDeactive(loans)"
                >
                  {{ changeVerfiedtext(loans) }}</button
                >&nbsp;
              </template>
              <template v-slot:cell(actions)="loans">
                <button
                  v-bind:class="'btn btn-info btn-sm'"
                  @click="editLoan(loans)"
                >
                  Edit</button
                >&nbsp;
                <button
                  v-bind:class="'btn btn-danger btn-sm'"
                  @click="deleteUser(loans)"
                >
                  Delete</button
                >&nbsp;
              </template>
              
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VerficationModal from "./VerficationModal";
import axios from "axios";
export default {
  page: {
    title: "Collateral",
  },
  
  components: {
    VerficationModal
  },
  data() {
    return {
      loans: [],
      alert:false,
      loading:true,
      alertText:'',
      isModel: false,
      modalBodyText:'',
      actionType:'',
      modalTitle:'',
      buttonStatus:'',
      title: "Users",
      showModal:false,
      checkedNames: [],
      customerId: "",
      userObj:{},
      isShowModel: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      showDeleteModal :false,
      sortDesc: true,
      fields: [
        { key: "id", label: "Select", sortable: true },
        { key: "username", label: "UserName", sortable: true },
        { key: "skip_id", label: "SkipId", sortable: true },
        { key: "detail", label: "Detail", sortable: true },
        { key: "actions" }
      ]
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    }
  },
  methods: {

    confirmationBulkAction(status) {
        if(this.checkedNames.length>0){
      if(status=='delete'){
        this.status='delete';
        this.modalBodyText='Do You Want To Delete This Record?';
      }
      if(status=='active'){
        this.status='active';
        this.modalBodyText='Do You Want To Active This Record?';
      }
      if(status=='deactive'){
        this.status='deactive';
        this.modalBodyText='Do You Want To Deactive This Record?';
      }
      this.$refs['my-modal'].show()
        }else{
          
          this.alert=true;
          this.alertText='Please Select checkbox';
          this.hideAlert();
        }
    },
    closeModal(value){
     this.isShowModel=value;
    },

    deleteRow(getUser){
      let index = this.loans.findIndex(
        (x) => x.id === getUser.item.id
      );
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert=true;
      this.alertText='Record Updated Successfully';
      this.hideAlert();
    },

    singlechangeStatus(getUser){
    let index = this.loans.findIndex(
        (x) => x.id === getUser.id
      );
      if (index !== -1) {
        this.loans[index].status=getUser.status;
      }
      this.alert=true;
      this.alertText='Record Updated Successfully';
      this.hideAlert();
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },
    hideAlert(){
      setTimeout(() => this.alert = false, 2000)
    },

    deleteUser(object){
      this.showModal = true
      this.isShowModel = true;
      this.userObj=object;
      this.actionType='delete';

    },
    singlActiveDeactive(object){
     this.showModal = true
      this.isShowModel = true;
      this.userObj=object;
      if(this.userObj.item.status=="1")
      {
        this.actionType='active';
      }
      if(this.userObj.item.status=="0")
      {
        this.actionType='deactive';
      }
    },
    addClassToButton(){
       const submitButton = this.$refs["kt_login_signin_submit"];
       submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-request-bulk-action", data, {
          // headers,
        }).then(response => {
          if (response.data.code == 200) {
            this.$refs['my-modal'].hide()
            this.getAllRecord();
            this.alert=true;
            this.alertText='Record Updated Successfully';
            this.checkedNames=[];
            this.hideAlert();
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllRecord() {
      try {
        axios.get(this.$path + "/skip-request-list").then(response => {
          if(response.data.code==400){
           this.loans = response.data.data;
          this.loading=false
          }else{
            this.loading=false
          }
          
          return response.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLoan() {
      this.$router.push({ name: "add-loan", params: { is_edit: false } });
    },

    editLoan(loan) {
      this.$router.push({
        name: "add-loan",
        params: { is_edit: true, loan_id: loan.item.id }
      });
    }
  },
  mounted() {
    this.totalRows = this.loans.length;
    this.getAllRecord();
  }
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
